<template>
  <Breadcrumb />
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <form class="row g-3 form-setmodel-new" novalidate>
            <div v-if="errors.length > 0" class="alert alert-warning">
              <ul class="mb-0">
                <li v-for="error in errors" v-bind:key="error">
                  {{ error }}
                </li>
              </ul>
            </div>
            <div v-if="isSuccess" class="alert alert-success">
              {{
                $t(
                  "Messages.SuccessMessage",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </div>
            <div class="col-md-6">
              <label for="Name" class="form-label required"
                >{{
                  $t(
                    "SetCodeModels.Name",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                type="text"
                class="form-control"
                id="Name"
                @keyup="functionNameKeyup()"
                v-model="setModelData.name"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <div class="col-md-6">
              <label for="FormulaName" class="form-label required">{{
                $t(
                  "SetCodeModels.FormulaName",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>

              <input
                type="text"
                class="form-control text-uppercase"
                id="FormulaName"
                @keyup="formulaNameKeyup()"
                v-model="setModelData.formulaName"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <div class="col-md-12">
              <label for="Description" class="form-label required"
                >{{
                  $t(
                    "SetCodeModels.Description",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                type="textarea"
                class="form-control"
                id="Description"
                v-model="setModelData.description"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <div class="col-md-12">
              <label for="ModelCode" class="form-label required"
                >{{
                  $t(
                    "SetCodeModels.ModelCode",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
                <div class="alert-info alert p-0 float-end ms-1 ps-1 pe-1 mb-0">
                  {{
                    $t(
                      "BaseModelFields.SwitchFullScreenMode",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </div>
              </label>
              <v-ace-editor
                v-model:value="setModelData.modelCode"
                id="modelCode"
                lang="csharp"
                theme="monokai"
                @init="editorInit"
                :options="options"
                style="height: 250px"
              />
            </div>
            <div class="col-md-6">
              <div class="form-check">
                <label for="IsActive" class="form-label">{{
                  $t(
                    "BaseModelFields.IsActive",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsActive"
                  v-model="setModelData.isActive"
                />
              </div>
            </div>
            <ActionButtons
              @methodSave="onSubmit('0', 'btn-save')"
              @methodSaveAndNew="onSubmit('1', 'btn-save-and-new')"
              @clear="onClear()"
              :isNewRecord="true"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { VAceEditor } from "vue3-ace-editor";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/snippets/javascript";
import "ace-builds/src-noconflict/ext-searchbox";
import ace from "ace-builds";
export default {
  name: "SetCodeModelNew",
  components: { VAceEditor },
  data() {
    return {
      editorInit: function (editor) {
        editor.setOptions({
          enableBasicAutocompletion: true,
          enableSnippets: true,
          enableLiveAutocompletion: true,
          autoScrollEditorIntoView: true,
        });
        editor.setShowPrintMargin(false);
      },
      options: {
        autoScrollEditorIntoView: true,
        enableBasicAutocompletion: true,
        enableSnippets: true,
        enableLiveAutocompletion: true,
        useWorker: true,
        wrap: true,
      },
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save, .btn-save-and-new, .btn-clear",
      errors: [],
      setModelData: {
        isActive: true,
      },
      isSuccess: false,
      modelCodeEditor: null,
    };
  },
  methods: {
    onClear() {
      this.setModelData = {
        isActive: true,
      };
      $(".form-setmodel-new").removeClass("was-validated");
    },
    formulaNameKeyup: function () {
      if (String.isNullOrWhiteSpace(this.setModelData.name)) {
        this.setModelData.formulaName = "";
        return;
      }
      this.setModelData.formulaName = String.replaceAlphanumeric(
        this.setModelData.name,
        "_"
      ).toUpperCase();
    },
    onSubmit(submitType, buttonName) {
      var form = $(".form-setmodel-new");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Brs-SetCodeModelNew", { ...this.setModelData })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            if (submitType == 0) {
              this.$router.push("/SetCode/Model/List");
            } else {
              this.isSuccess = true;
              setTimeout(
                () => (this.isSuccess = false),
                this.$appSaveAndNewMessageDuration
              );
              this.onClear();
              firstSpan.show();
              loadingBlock.hide();
              disabledButtons.prop("disabled", false);
              form.removeClass("was-validated");
            }
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
    functionNameKeyup: function () {
      if (String.isNullOrWhiteSpace(this.setModelData.name)) {
        this.setModelData.formulaName = "";
        return;
      }
      this.setModelData.formulaName = String.replaceAlphanumeric(
        this.setModelData.name,
        "_"
      ).toUpperCase();
    },
  },
  mounted() {
    this.modelCodeEditor = ace.edit("modelCode");
    this.modelCodeEditor.commands.addCommand(
      this.$root.ace.commands.fullScreen
    );
  },
};
</script>
